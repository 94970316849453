import React from "react";
import AnimateIn from "../../../oat-ui/animation/Animate";
import {
  Content,
  LayoutOne,
  WrapLayoutOne,
  Header,
  WrapImage,
} from "./styles/layoutOneStyles";
import styled from "styled-components";
import {defaultMaxWidthDesktop, defaultMaxWidthLaptop, layoutPadding} from "../layoutVariables";
import {homePageBackground1} from "./styles/layoutStyles";
import {WrapHeader} from "./FeatureList";
import {HomeHeader} from "./FeaturesShowcase2";
import { getWindowSize } from "../../../oat-window-size/getWindowSize";
// import {ReactComponent as Illustration} from "./icon/illustration.svg";

const StickersIconsSection = () => {
  const { width } = getWindowSize();
  return (
    <Layout>
      <Section>
        <WrapHeader>
          <AnimateIn>
            <HomeHeader>Designing should be fun</HomeHeader>
          </AnimateIn>
        </WrapHeader>

        <WrapLayoutOne>
          <AnimateIn style={{flex: 1}} delay={width > 768 ? 0.3 : 0.3}>
            <LayoutOne
              index={1}
              style={{
                background: `linear-gradient(rgb(243, 243, 255), rgb(250 231 235))`,
              }}
            >
              <Content>
                <Header>
                  <h3>Illustrations</h3>
                  {/* <img
                    src="https://dnaeh50kru9rd.cloudfront.net/home-page/illustration-icon-100.png"
                    alt="workspace"
                    loading="lazy"
                  /> */}
                </Header>

                <p>
                  Discover illustrations of various styles, with a flexibility
                  to customize colors to suit your preferences.
                </p>
              </Content>

              <WrapImage>
                {/* <img
                  src="https://www.notion.so/cdn-cgi/image/format=auto,width=1080,quality=100/front-static/pages/home/customize-info.png"
                  alt="workspace-list"
                /> */}

                {/* <Illustration /> */}
                <Image
                  src="https://dnaeh50kru9rd.cloudfront.net/home-page/space-illustration-500x.png"
                  alt=""
                  loading="lazy"
                />
              </WrapImage>
            </LayoutOne>
          </AnimateIn>

          <AnimateIn style={{flex: 1}} delay={width > 768 ? 0.65 : 0.3}>
            <LayoutOne
              index={2}
              style={{
                background: `linear-gradient(rgba(204, 225, 244, 0.1), rgb(229 231 255))`,
              }}
            >
              <Content>
                <Header>
                  <h3>Stickers</h3>
                  {/* <img
                    src="https://dnaeh50kru9rd.cloudfront.net/home-page/sticker-icon-100.png"
                    alt="folder"
                    loading="lazy"
                  /> */}
                </Header>

                <p>
                  Stay on top of the latest trends with our collection of cool
                  stickers.
                </p>
              </Content>

              <WrapImage>
                <Image
                  src="https://dnaeh50kru9rd.cloudfront.net/home-page/stickers-illustration-500x.png"
                  alt="stickers-&-illustrations"
                  loading="lazy"
                />
              </WrapImage>
            </LayoutOne>
          </AnimateIn>

          <AnimateIn style={{flex: 1}} delay={width > 768 ? 0.95 : 0.3}>
            <LayoutOne
              index={3}
              style={{
                background: `linear-gradient(rgba(204, 225, 244, 0.1), rgb(244 232 255))`,
              }}
            >
              <Content>
                <Header>
                  <h3>Charts & Table</h3>
                  {/* <img
                    src="https://dnaeh50kru9rd.cloudfront.net/home-page/chart-icon-100.png"
                    alt="workspace"
                    loading="lazy"
                  /> */}
                </Header>

                <p>
                  Visualize data and information with our built-in charts and
                  table features
                </p>
              </Content>

              <WrapImage>
                {/* <img
                  src="https://www.notion.so/cdn-cgi/image/format=auto,width=1080,quality=100/front-static/pages/home/customize-info.png"
                  alt="workspace-list"
                /> */}

                {/* <Illustration /> */}
                <Image
                  src="https://dnaeh50kru9rd.cloudfront.net/home-page/chart-illustration-500x.png"
                  alt=""
                  loading="lazy"
                />
              </WrapImage>
            </LayoutOne>
          </AnimateIn>
        </WrapLayoutOne>
      </Section>
    </Layout>
  );
};

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${homePageBackground1};
  @media only screen and (min-width: 601px) {
    padding: 6rem ${layoutPadding.desktop} 4.8rem;
  }
  @media only screen and (max-width: 600px) {
    padding: 3.2rem ${layoutPadding.mobile} 1.6rem;
  }
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 3.6rem;
  @media only screen and (min-width: 1000px) and (max-width: 1179px) {
    max-width: 94%;
  }
  @media only screen and (min-width: 1180px) and (max-width: 1500px) {
    max-width: ${defaultMaxWidthLaptop}px;
  }
  @media only screen and (min-width: 1501px) {
    max-width: ${defaultMaxWidthDesktop}px;
  }
  @media only screen and (max-width: 899px) {
    margin-bottom: 0;
  }
`;

const Image = styled.img`
  max-width: 100%;
  object-fit: contain;
  transition: all 400ms ease-out;
  transform: scale(0.7) rotate(0deg);
  &:hover {
    transform: scale(0.8);
  }
`;

export default StickersIconsSection;
